import {Controller} from 'stimulus';
import moment from 'moment';
import range from 'lodash/range';

export default class extends Controller {
  static targets = [
    'yearSelect', 'monthSelect', 'daySelect', 'hiddenInput'
  ];

  initialize() {

  }

  updateDate(e) {
    const values = [
      this.yearSelectTarget.value,
      this.monthSelectTarget.value,
      this.daySelectTarget.value
    ];
    const daysInMonth = moment(`${values[0]}-${values[1]}`, 'YYYY-MM').daysInMonth();

    this.daySelectTarget.options.length = 0;

    range(1, daysInMonth + 1).forEach(day => {
      // Selected if day is also in new month, otherwise get last day
      const selected = day == values[2];

      this.daySelectTarget.add(new Option(day, day, false, selected));
    });

    this.hiddenInputTarget.value = values.join('-');
  }
}
