import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'name',
    'subcontractor',
    'nameField',
    'categorySelect',
    'categoryTextField',
    'subcontractorSelect',
    'subcontractorNameField'
  ];

  connect() {
    const selectedCategory = this.categorySelectTarget.value;

    if (selectedCategory === 'Subcontractor') {
      this.subcontractorTarget.classList.remove('dn');
    } else if (selectedCategory === ('Misc' || 'Supplies')) {
      this.nameFieldTarget.classList.remove('dn');
    }
  }

  categoryChange(e) {
    const select = e.currentTarget;
    const selectedFunction = `_${select.value.toLowerCase()}Selected`;

    if (this[selectedFunction] !== undefined) {
      this[selectedFunction]();
    } else {
      this.nameTarget.classList.remove('dn');
      this.nameFieldTarget.value = '';
      this.subcontractorTarget.classList.add('dn');
      if (this.hasSubcontractorSelectTarget) this.subcontractorSelectTarget.disabled = true;
      this.subcontractorNameFieldTarget.disabled = true;
    }
  }

  subcontractorChange(e) {
    if (this.subcontractorSelectTarget.value === '0') {
      this.subcontractorSelectTarget.disabled = true;
      this.subcontractorSelectTarget.parentNode.parentNode.classList.add('dn');
      this.subcontractorNameFieldTarget.value = '';
      this.subcontractorNameFieldTarget.parentNode.classList.remove('dn');
      this.subcontractorNameFieldTarget.disabled = false;
      this.subcontractorNameFieldTarget.focus();
    } else {
      this.subcontractorSelectTarget.disabled = false;
      this.subcontractorSelectTarget.parentNode.parentNode.classList.remove('dn');
      this.subcontractorNameFieldTarget.parentNode.classList.add('dn');
    }
  }

  _subcontractorSelected() {
    this.subcontractorTarget.classList.remove('dn');
    this.nameTarget.classList.add('dn');
    this.nameFieldTarget.value = '';

    if (this.hasSubcontractorSelectTarget) {
      this.subcontractorSelectTarget.disabled = false;
      this.subcontractorSelectTarget.selectedIndex = 0;
      this.subcontractorSelectTarget.parentNode.parentNode.classList.remove('dn');
      this.subcontractorNameFieldTarget.disabled = true;
      this.subcontractorNameFieldTarget.parentNode.classList.add('dn');
    } else {
      this.subcontractorNameFieldTarget.disabled = false;
    }
  }

  _customSelected() {
    this.nameTarget.classList.remove('dn');
    this.subcontractorTarget.classList.add('dn');
    if (this.hasSubcontractorSelectTarget) this.subcontractorSelectTarget.disabled = true;
    this.subcontractorNameFieldTarget.disabled = true;
    this.categoryTextFieldTarget.classList.remove('dn');
    this.categoryTextFieldTarget.disabled = false;
    this.categoryTextFieldTarget.value = '';
    this.categoryTextFieldTarget.focus();
    this.categoryTextFieldTarget.previousElementSibling.classList.add('dn');
  }
}
