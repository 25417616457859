import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = []

  connect() {
    const center = this.data.get('center');
    const panBy = this.data.get('panby');
    const addresses = document.querySelectorAll('[data-map-coordinates]');
    const trafficLayer = new google.maps.TrafficLayer();

    this.map = new google.maps.Map(this.element, {
      scrollwheel: false,
      mapTypeControl: false,
      streetViewControl: false,
      styles: [ { "featureType": "administrative.neighborhood", "elementType": "geometry.fill", "stylers": [ { "hue": "#ff0000" }, { "visibility": "on" } ] }, { "featureType": "administrative.neighborhood", "elementType": "labels.text.fill", "stylers": [ { "visibility": "off" } ] }, { "featureType": "administrative.neighborhood", "elementType": "labels.text.stroke", "stylers": [ { "visibility": "off" } ] }, { "featureType": "landscape.man_made", "elementType": "geometry", "stylers": [ { "color": "#f7f1df" } ] }, { "featureType": "landscape.natural", "elementType": "geometry", "stylers": [ { "color": "#d0e3b4" } ] }, { "featureType": "landscape.natural.terrain", "elementType": "geometry", "stylers": [ { "visibility": "off" } ] }, { "featureType": "poi", "elementType": "labels", "stylers": [ { "visibility": "off" } ] }, { "featureType": "poi.business", "stylers": [ { "visibility": "off" } ] }, { "featureType": "poi.medical", "elementType": "geometry", "stylers": [ { "color": "#fbd3da" } ] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [ { "color": "#bde6ab" } ] }, { "featureType": "road", "elementType": "geometry.stroke", "stylers": [ { "visibility": "off" } ] }, { "featureType": "road", "elementType": "labels", "stylers": [ { "visibility": "simplified" } ] }, { "featureType": "road.arterial", "elementType": "geometry.fill", "stylers": [ { "color": "#ffffff" } ] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [ { "color": "#ffe15f" } ] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [ { "color": "#efd151" } ] }, { "featureType": "road.local", "elementType": "geometry.fill", "stylers": [ { "color": "black" } ] }, { "featureType": "transit.station.airport", "elementType": "geometry.fill", "stylers": [ { "color": "#cfb2db" } ] }, { "featureType": "water", "elementType": "geometry", "stylers": [ { "color": "#a2daf2" } ] } ],
      center: center ? this._parseCoordinates(center) : null,
      zoom: center ? 12 : null
    });

    trafficLayer.setMap(this.map);

    if (addresses.length === 1) this._showMarker(addresses[0]);
    if (addresses.length > 1) this._showAllMarkers(addresses);

    if (panBy) {
      const values = panBy.split(',').map(x => parseInt(x));
      this.map.panBy(...values);
    }
  }


  _showAllMarkers(addresses) {
    const bounds = new google.maps.LatLngBounds();

    addresses.forEach((address, i) => {
      if (address.getAttribute('data-map-coordinates')) {
        const marker = this._buildCircleMarker(address);

        bounds.extend(marker.position);
      }
    });

    this.map.fitBounds(bounds);
  }

  _showMarker(address) {
    const marker = this._buildMarker(address);

    this.map.setCenter(marker.position);
    this.map.setZoom(15);
  }

  _buildCircleMarker(address) {
    const label = address.getAttribute('data-map-marker-label');
    const icon = {
      // FA CIRCLE
      path: 'M55.296-32.183q0 7.524-3.708 13.878t-10.062 10.062-13.878 3.708-13.878-3.708-10.062-10.062-3.708-13.878 3.708-13.878 10.062-10.062 13.878-3.708 13.878 3.708 10.062 10.062 3.708 13.878z',
      fillColor: '#4A98F3',
      fillOpacity: 1,
      scale: 0.55,
      strokeWeight: 0,
      // Fix label/anchor for FA SVG
      labelOrigin: new google.maps.Point(28.25, -32),
      anchor: new google.maps.Point(18.25, -5)
    };

    return new google.maps.Marker({
      position: this._parseCoordinates(address.getAttribute('data-map-coordinates')),
      animation: google.maps.Animation.DROP,
      map: this.map,
      icon: icon,
      label: {
        text: label,
        color: '#ffffff',
        fontWeight: '600',
        fontFamily: 'Poppins'
      }
    });
  }

  _buildMarker(address) {
    const label = address.getAttribute('data-map-marker-label');
    const icon = {
      // FA MAP_MARKER
      path: 'M27.648-41.399q0-3.816-2.7-6.516t-6.516-2.7-6.516 2.7-2.7 6.516 2.7 6.516 6.516 2.7 6.516-2.7 2.7-6.516zm9.216 0q0 3.924-1.188 6.444l-13.104 27.864q-.576 1.188-1.71 1.872t-2.43.684-2.43-.684-1.674-1.872l-13.14-27.864q-1.188-2.52-1.188-6.444 0-7.632 5.4-13.032t13.032-5.4 13.032 5.4 5.4 13.032z',
      fillColor: '#9143B5',
      fillOpacity: 1,
      scale: 0.7,
      strokeWeight: 0,
      labelOffset: new google.maps.Point(30,-30)
    };

    return new google.maps.Marker({
      position: this._parseCoordinates(address.getAttribute('data-map-coordinates')),
      animation: google.maps.Animation.DROP,
      map: this.map,
      icon: icon,
    });
  }

  _parseCoordinates(coordinates) {
    coordinates = coordinates.split(',');

    return { lat: parseFloat(coordinates[0]), lng: parseFloat(coordinates[1]) };
  }

}