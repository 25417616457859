import { Controller } from 'stimulus'
import moment from 'moment';
import _ from 'lodash/core';
import groupBy from 'lodash/groupBy';
import axios from 'axios';

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'h:mm A';

export default class extends Controller {
  static targets = [
    'input',
    'page',
    'circle',
    'currentDateHeading',
    'dateHeading',
    'newAppointmentLink',
    'appointmentsHeading',
    'appointmentTemplate',
    'appointmentList',
    'month',
    'days',
    'noData'
  ]

  connect() {
    this.date = moment();
    this.dayTemplate = this.daysTarget.firstElementChild.cloneNode(true);


    if (this.hasAppointmentTemplateTarget) {
      this.appointmentTemplate = this.appointmentTemplateTarget.cloneNode(true);
    }

      const startsAt = moment(this.date).startOf('month');
      const endsAt = moment(this.date).endOf('month');

    this._fetchAppointments(startsAt, endsAt)
      .then(this.render.bind(this))
      .then(() => {
        this._selectDate(this.hasInputTarget ? this.inputTarget.value : this.date);
      });
  }

  render() {
    const startOfMonth = moment(this.date).startOf('month');
    const endOfMonth = moment(this.date).endOf('month');

    if (this.date.year() !== moment().year()) {
      const year = `<span class="f5 fw1 silver">${this.date.format('YYYY')}</span>`;
      this.monthTarget.innerHTML = `${this.date.format('MMMM')} ${year}`;
    } else {
      this.monthTarget.innerHTML = this.date.format('MMMM');
    }

    this.daysTarget.innerHTML = '';

    // last month
    for (var d = startOfMonth.day(); d > 0; d--) {
      const prevDate = moment(startOfMonth).subtract(d, 'day');
      const dayElement = this._buildDay(prevDate);

      dayElement.classList.add('silver');
      this.daysTarget.appendChild(dayElement);
    }

    // this month
    for (var d = 1; d <= this.date.daysInMonth(); d++) {
      const date = moment(startOfMonth).add(d - 1, 'day');
      const dayElement = this._buildDay(date);

      dayElement.classList.add('fw6');
      dayElement.classList.remove('silver');
      this.daysTarget.appendChild(dayElement);
    }

    // next month
    for (var d = 1; (d + endOfMonth.day()) < 7; d++) {
      const nextDate = moment(endOfMonth).add(d, 'day');
      const dayElement = this._buildDay(nextDate);

      dayElement.classList.add('silver');
      this.daysTarget.appendChild(dayElement);
    }

    const prevMonth = moment(startOfMonth).subtract(1, 'day');
    const prevMonthLink = this.element.querySelector('[data-action="click->calendar#prevMonth"]');
    const nextMonth = moment(endOfMonth).add(1, 'day');
    const nextMonthLink = this.element.querySelector('[data-action="click->calendar#nextMonth"]');

    prevMonthLink.querySelector('.name').innerHTML = prevMonth.format('MMM')
    nextMonthLink.querySelector('.name').innerHTML = nextMonth.format('MMM')
  }

  // Private

  _buildDay(date) {
    const element = this.dayTemplate.cloneNode(true);
    const dayElement = element.firstElementChild;
    const dateKey = date.format(DATE_FORMAT);
    const appointments = this.appointments[dateKey] || [];

    const appointmentBubbles = `
      <span class="dib absolute bottom-0 left-0 right-0 f5 purple">
        ${appointments.map(e => '•').join('')}
      <span>
    `;

    element.removeAttribute('data-target');
    element.classList.remove('dn');
    dayElement.setAttribute('data-date', dateKey);
    dayElement.innerHTML = date.format('D') + appointmentBubbles;

    if (date.isSame(new Date(), 'day')) {
      const today = document.createElement('h5');
      today.classList.add('mv0', 'pt1', 'absolute', 'top-0', 'left-0', 'right-0', 'f7', 'tc');
      today.innerHTML = 'TODAY';

      dayElement.appendChild(today);
    }

    return element;
  }

  _fetchAppointments(startsAt, endsAt) {
    return axios.get('/api/v1/appointments', {
      params: {
        starts_at: startsAt.format(DATE_FORMAT),
        ends_at: endsAt.format(DATE_FORMAT)
      }
    }).then(res => {
       this.appointments = groupBy(res.data.appointments, e => moment(e.startsAt).format(DATE_FORMAT));
    });
  }

  _selectDate(date) {
    date = moment(date);

    if (this.hasDateHeadingTarget) {
      const heading = this.dateHeadingTarget;
      const dayAnchor = heading.querySelector('.date .day');

      heading.querySelector('.weekday').innerHTML = date.format('dddd');
      heading.querySelector('.date .month').innerHTML = date.format('MMM');
      dayAnchor.innerHTML = date.format('D');

      if (this.hasNewAppointmentLinkTarget) {
        const newHref = this.newAppointmentLinkTarget.getAttribute('href').replace(/(date=).+$/, `$1${date.format(DATE_FORMAT)}`)
        this.newAppointmentLinkTarget.setAttribute('href', newHref);
        dayAnchor.setAttribute('href', newHref)
      }
    }

    [...this.daysTarget.children].forEach(n => {
      n.firstElementChild.classList.remove('white', 'bg-blue')
      n.querySelector('span > span').classList.add('purple');
      n.querySelector('span > span').classList.remove('white');
    });

    const fullDay = this.element.querySelector(`[data-date='${date.format(DATE_FORMAT)}']`);

    if (fullDay) {
      fullDay.classList.add('white', 'bg-blue');
      fullDay.querySelector('span > span').classList.add('white');
      fullDay.querySelector('span > span').classList.remove('purple');
    }

    this._updateTimes(date);

    if (this.hasAppointmentListTarget) {
      this._showAppointments(date);
    }
  }

  _updateTimes(date) {
    const appointments = ((this.appointments || {})[date.format(DATE_FORMAT)] || []);
    const timeSelect = document.querySelector('[data-target="appointment.timeSelect"]');

    if (timeSelect) {
      [...timeSelect.options].forEach(option => {
        option.innerHTML = option.value;

        const dateTime = moment(`${date.format(DATE_FORMAT)} ${option.value}`, `${DATE_FORMAT} ${TIME_FORMAT}`);

        if (appointments.length && _.some(appointments, e => dateTime.isBetween(e.startsAt, e.endsAt, null, '[)'))) {
          option.innerHTML = dateTime.format(TIME_FORMAT) + ' - Booked';
        } else {
          option.innerHTML = dateTime.format(TIME_FORMAT);
        }
      });
    }
  }

  _showAppointments(date) {
    const appointments = ((this.appointments || {})[date.format(DATE_FORMAT)] || []);
    const appointmentsList = this.appointmentListTarget;

    appointmentsList.innerHTML = '';

    if (appointments.length) {
      this._hideNoData();
      if (this.hasAppointmentsHeadingTarget) {
        this.appointmentsHeadingTarget.classList.remove('dn');
        this.appointmentsHeadingTarget.innerHTML = `${appointments.length} appointment${appointments.length > 1 ? 's' : ''} scheduled for this date`;
      }

      appointmentsList.classList.remove('dn');

      appointments.forEach(appointment => {
        const element = this.appointmentTemplate.cloneNode(true);
        const address = appointment.job.customer.address;
        const notes = element.querySelector('.notes');
        const anchor = element.querySelector('.name a');

        element.classList.remove('dn');
        element.classList.add('flex');

        element.querySelector('.time').innerHTML = moment(appointment.startsAt).format(TIME_FORMAT);
        element.querySelector('.duration').innerHTML = appointment.duration;
        anchor.innerHTML = appointment.job.customer.name;
        anchor.setAttribute('href', appointment.job.url);

        if (address) {
          const street = element.querySelector('.street');

          if (street) street.innerHTML = `${address.street1} ${address.street2}`;
          element.querySelector('.city').innerHTML = address.city;
          element.querySelector('.state').innerHTML = address.state;
        } else {
          element.querySelector('.street').innerHTML = `<i class="f6 silver">Address not provided</i>`;
        }

        if (notes && appointment.notes && appointment.notes !== '') {
          notes.innerHTML = appointment.notes;
          notes.classList.remove('dn');
        }

        appointmentsList.appendChild(element);
      });
    } else {
      this._showNoData(date);
    }
  }

  _hideNoData() {
    this.noDataTarget.classList.add('dn');
  }

  _showNoData(date) {
    this.noDataTarget.classList.remove('dn');
    [...this.noDataTarget.querySelectorAll('a')].forEach(anchor => {
      let href = anchor.getAttribute('href');

      if (/\?date=/.test(href)) {
        href = href.replace(/\?date=[0-9-]+/, `?date=${date.format(DATE_FORMAT)}`);
      } else {
        href = href.replace('?', `?date=${date.format(DATE_FORMAT)}&`);
      }

      anchor.setAttribute('href', href);
    });
  }

  // Actions

  show(e) {
    e.preventDefault();
    this.currentDateHeadingTarget.classList.add('dn');
    this.pageTarget.classList.remove('dn');
  }

  prevMonth(e) {
    e.preventDefault();
    if (e.currentTarget.hasAttribute('data-disabled')) return;

    this.date.subtract(1, 'month');
    const startsAt = moment(this.date).startOf('month');
    const endsAt = moment(this.date).endOf('month');

    if (this.hasAppointmentsHeadingTarget) {
     this.appointmentsHeadingTarget.classList.add('dn');
    }

    if (this.hasAppointmentListTarget) {
      this.appointmentListTarget.classList.add('dn');
    }

    this._fetchAppointments(startsAt, endsAt).then(this.render.bind(this));
    this._updateTimes(this.date);
  }

  nextMonth(e) {
    e.preventDefault();

    if (e.currentTarget.hasAttribute('data-disabled')) return;

    this.date.add(1, 'month');
    const startsAt = moment(this.date).startOf('month');
    const endsAt = moment(this.date).endOf('month');

    if (this.hasAppointmentsHeadingTarget) {
     this.appointmentsHeadingTarget.classList.add('dn');
    }

    if (this.hasAppointmentListTarget) {
      this.appointmentListTarget.classList.add('dn');
    }

    this._fetchAppointments(startsAt, endsAt).then(this.render.bind(this));
    this._updateTimes(this.date);
  }

  selectDate(e) {
    e.preventDefault();

    const date = moment(e.currentTarget.dataset.date);

    if (this.hasInputTarget) {
      this.inputTarget.value = date.format(DATE_FORMAT);
    }

    this._selectDate(date);
  }
}