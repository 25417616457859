import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = [
    'nav'
  ];

  initialize() {
    // ie 11 doesn't support forEach on querySelectorAll so it needs to be changed to an array
    this.dropdowns = [...document.querySelectorAll('[data-controller="dropdown"]')];

    document.querySelector('body').addEventListener('click', e => {
      this.dropdowns.forEach(nav => {
        nav.classList.remove('open');
      })
    });
  }

  toggle(e) {
    e.preventDefault();
    e.stopPropagation();

    this.element.classList.toggle('open');
  }
}
