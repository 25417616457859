import { Controller } from 'stimulus'
import _ from 'lodash/core';

export default class extends Controller {
  static targets = [ 'currentLogo', 'logoFields', 'placesInput', 'place', 'places' ];

  initialize() {
    if (this.hasPlacesInputTarget) {
      this.initPlacesAutocomplete();
    }
  }

  initPlacesAutocomplete() {
    const input = this.placesInputTarget;
    const placeTemplate = this.placeTargets[0];
    const places = this.placesTarget;

    if (input) {
      const autocomplete = new google.maps.places.Autocomplete(input, {
       types: ['(regions)'],
       componentRestrictions: { country: 'us' }
      });

      autocomplete.addListener('place_changed', () => {
         const place = autocomplete.getPlace();

         if (!place.geometry) {
           // User entered the name of a Place that was not suggested and
           // pressed the Enter key, or the Place Details request failed.
           return;
         }

         const placeElm = placeTemplate.cloneNode(true);
         const index = this.placeTargets.length;
         const container = places.querySelector('.places');

         placeElm.removeAttribute('id');
         placeElm.classList.remove('dn');

         placeElm.prepend(place.formatted_address);


         [...placeElm.querySelectorAll('input')].forEach(input => {
           input.disabled = false;
           input.setAttribute('name', input.getAttribute('name').replace(/\[0\]/, `[${index}]`));
         });

         placeElm.querySelector('input[name*="name"]').value = place.name;
         placeElm.querySelector('input[name*="place_type"]').value = place.types[0];
         placeElm.querySelector('input[name*="country"]').value = (_.find(place.address_components, c => c.types[0] === 'country') || {}).long_name;
         placeElm.querySelector('input[name*="administrative_area_level_1"]').value = (_.find(place.address_components, c => c.types[0] === 'administrative_area_level_1') || {}).long_name;
         placeElm.querySelector('input[name*="administrative_area_level_2"]').value = (_.find(place.address_components, c => c.types[0] === 'administrative_area_level_2') || {}).long_name;
         placeElm.querySelector('input[name*="locality"]').value = (_.find(place.address_components, c => c.types[0] === 'locality') || {}).long_name;
         placeElm.querySelector('input[name*="postcode"]').value = (_.find(place.address_components, c => c.types[0] === 'postal_code') || {}).long_name;
         placeElm.querySelector('input[name*="google_place_id"]').value = place.place_id;

         container.insertBefore(placeElm, container.querySelector('[data-target="company.place"]'));

         input.value = '';
         input.focus();
       });
    }
  }

  removePlace(e) {
    e.preventDefault();

    const place = e.target.parentNode;
    const form = place.closest('form');
    const idInput = place.querySelector('input[name*="[id]"]');
    const destroyInput = place.querySelector('input[name*="_destroy"]');

    if (idInput) {
      idInput.disabled = false;
      destroyInput.disabled = false;
      destroyInput.setAttribute('value', 'true');
      place.classList.add('dn');
    } else {
      place.parentNode.removeChild(place);
    }
  }

  showLogoField(e) {
    e.preventDefault();

    this.currentLogoTarget.classList.add('dn');
    this.logoFieldsTarget.classList.remove('dn');
  }

  logoInputChanged(e) {
		const input = e.target;
    const label = input.previousElementSibling;
    const labelVal = label.innerHTML;

		if (input.value) {
			const fileName = input.value.split( '\\' ).pop();

      if (input.getAttribute('data-image') && !/\.(jpg|jpeg|png|gif)$/i.test(fileName)) {
        label.classList.add('ba');
        label.style['border-color'] = '#E84242';
      }

      label.querySelector('.filename').innerHTML = fileName;
		} else {
      label.innerHTML = labelVal;
		}

		// Firefox bug fix
    // input.on( 'focus', function(){ input.addClass( 'has-focus' ); })
         // .on( 'blur', function(){ input.removeClass( 'has-focus' ); });
  }

  validateSubdomain(e) {
    const input = e.target;
    const value = input.value.toLowerCase();
    const preview = input.nextElementSibling;

    if (!/^[a-z\d]+(-[a-z\d]+)*$/i.test(value)) {
      input.classList.add('error');
      preview.classList.remove('blue')
      preview.classList.add('red');
    } else {
      input.classList.remove('error');
      preview.classList.remove('red');
      preview.classList.add('blue');
    }

    preview.innerHTML = value + '.workweek.com';
  }

  facebookAccountSelected(e) {
    e.preventDefault();
    e.target.closest('form').querySelector('button').disabled = false;
  }
}
