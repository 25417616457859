import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'lineItem',
    'href',
    'total',
    'recipientsList',
    'recipientField',
    'emailMessageCallout',
    'emailMessage'
  ]

  initialize() {
    const lineItem = this.element.querySelector('.line-item');

    if (lineItem) {
      this.lineItemTemplate = lineItem.cloneNode(true);
    }

    if (this.hasRecipientFieldTarget) {
      this.recipientTemplate = this.recipientFieldTarget.cloneNode(true);
      this.recipientTemplate.querySelector('input').value = '';
    }
  }

  visit(e) {
    e.preventDefault();

    Turbolinks.visit(this.hrefTarget.getAttribute('href'));
  }

  submit(e) {
    this.lineItemTargets.forEach(line => {
      const lineItemController = this.application.getControllerForElementAndIdentifier(line, 'line-item');

      lineItemController.validate();
    });

    const errors = this.element.querySelector('.error');

    if (errors) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  updateTotals() {
    let total = 0.00;

    this.lineItemTargets.forEach(line => {
      const quantity = line.querySelector('.quantity').value.trim();
      const price = line.querySelector('.price').value.trim();
      const destroyInput = line.querySelector('input[name*="_destroy"]');

      // Skip the line if it's invalid
      if (!(/^\d+$/.test(quantity) && /^\d+(\.\d{1,2})?$/.test(price))) return;

      // Skip the line if it's being removed
      if (destroyInput && !destroyInput.hasAttribute('disabled')) return;

      total += (parseInt(quantity) * parseFloat(price));
    });

    this.totalTarget.innerHTML = '$' + total.toFixed(2)
  }

  addLineItem(e) {
    e.preventDefault();

    const lineCount = this.element.querySelectorAll('.line-item').length;
    const newLine = this.lineItemTemplate.cloneNode(true);

    [...newLine.querySelectorAll('input.description, input.quantity, input.price')].forEach(input => {
      const nameAttr = input.getAttribute('name');
      const idAttr = input.getAttribute('id');

      input.setAttribute('name', nameAttr.replace(/\[\d+\]/, `[${lineCount}]`));
      input.setAttribute('id', idAttr.replace(/_\d+_/, `_${lineCount}_`));

      if (input.classList.contains('quantity')) {
        input.value = '1';
      } else {
        input.value = '';
      }

    });

    const idInput = newLine.querySelector('input[name*="id"]');
    const destroyInput = newLine.querySelector('input[name*="_destroy"]');

    idInput && idInput.remove();
    destroyInput && destroyInput.remove();

    const tbody = this.element.querySelector('tbody');

    tbody.insertBefore(newLine, tbody.children[tbody.children.length-1]);
    newLine.querySelector('.description').focus();
  }

  addRecipient(e) {
    e.preventDefault();

    const newRecipient = this.recipientTemplate.cloneNode(true);
    const input = newRecipient.querySelector('input');

    this.recipientsListTarget.appendChild(newRecipient);
    input.focus();
  }

  removeRecipient(e) {
    e.preventDefault();

    if (this.recipientFieldTargets.length > 1) {
      const recipientField = e.currentTarget.closest('[data-target="accountable.recipientField"]');

      this.recipientsListTarget.removeChild(recipientField);
    }
  }

  showEmailMessage(e) {
    e.preventDefault();

    [...this.emailMessageCalloutTarget.querySelectorAll('span')].forEach(elm => elm.classList.toggle('dn'));

    this.emailMessageTarget.classList.remove('dn');
    this.emailMessageTarget.disabled = false;
    this.emailMessageTarget.focus();
  }

  hideEmailMessage(e) {
    e.preventDefault();

    const textArea = this.emailMessageTarget.querySelector('textarea');

    [...this.emailMessageCalloutTarget.querySelectorAll('span')].forEach(elm => elm.classList.toggle('dn'));
    this.emailMessageTarget.classList.add('dn');
    this.emailMessageTarget.disabled = true;
  }
}