import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = [
    'errorMessage',
    'checkHelp',
    'routingNumber',
    'accountNumber',
    'ssn',
    'taxId'
  ];

  submitPaymentsForm(e) {
    e.preventDefault();

    const form = e.currentTarget;
    const submitButton = form.querySelector('[type="submit"]');

    this.clearErrors(form);
    this.validateFields();

    if (form.querySelector('.error')) return false;

    const hasToken = !!form.querySelector('input[name*="external_account"]').length;
    if (hasToken) return true;

    submitButton.disabled = true;
    submitButton.classList.add('loading');

    Stripe.bankAccount.createToken(form, (status, response) => {
      if (response && response.error) {
        submitButton.disabled = false;
        submitButton.classList.remove('loading');

        return this.showError(form, response.error);
      }

      form.querySelector('input[name*="external_account"]').value = response.id;

      form.submit();
    });

    return false;
  }

  validateFields() {
    this.validateAccountNumber();
    this.validateRoutingNumber();
    this.validateSSN();
    this.validateFirstName();
    this.validateLastName();
    this.validateStatementDescriptor();
    this.validateProductDescription();
    this.validateTosAgreement();
  }

  validateRoutingNumber() {
    const input = this.routingNumberTarget;

    if (Stripe.bankAccount.validateRoutingNumber(input.value, 'US')) {
      input.classList.remove('error');
    } else {
      input.classList.add('error');
    }
  }

  businessTypeChange(e) {
    const select = e.currentTarget;
    const companyFields = this.companyFieldsTarget;
    const taxId = this.taxIdTarget;

    if (select.value === 'company') {
      taxId.disabled = false;
      taxId.parentElement.classList.remove('dn');
    } else {
      taxId.disabled = true;
      taxId.parentElement.classList.add('dn');
    }
  }

  validateAccountNumber() {
    const input = this.accountNumberTarget;

    if (Stripe.bankAccount.validateAccountNumber(input.value, 'US')) {
      input.classList.remove('error');
    } else {
      input.classList.add('error');
    }
  }

  validateFirstName() {
    const input = this.element.querySelector('input[name*="first_name"]');

    if (/^\w+$/.test(input.value)) {
      input.classList.remove('error');
    } else {
      input.classList.add('error');
    }
  }

  validateLastName() {
    const input = this.element.querySelector('input[name*="last_name"]');

    if (/^\w+$/.test(input.value)) {
      input.classList.remove('error');
    } else {
      input.classList.add('error');
    }
  }

  validateStatementDescriptor() {
    const input = this.element.querySelector('input[name*="statement_descriptor"]');

    if (/^[^<>*'"]{1,22}$/.test(input.value)) {
      input.classList.remove('error');
    } else {
      input.classList.add('error');
    }
  }

  validateProductDescription() {
    const input = this.element.querySelector('textarea[name*="product_description"]');

    if (input.value.length > 0) {
      input.classList.remove('error');
    } else {
      input.classList.add('error');
    }
  }

  validateSSN() {
    const input = this.ssnTarget;

    if (/^\d{4}$/.test(input.value)) {
      input.classList.remove('error');
    } else {
      input.classList.add('error');
    }
  }

  validateTosAgreement() {
    const checkBox = document.querySelector('input[type="checkbox"]');
    const errorMessage = checkBox.nextElementSibling.nextElementSibling.querySelector('span');

    if (checkBox.checked) {
      errorMessage.classList.remove('error');
      errorMessage.parentElement.classList.add('dn');
    } else {
      errorMessage.classList.add('error');
      errorMessage.parentElement.classList.remove('dn');
    }
  }

  showError(form, err) {
    const name = err.param.replace('_', '-');
    const elem = form.querySelector('[data-stripe="' + name + '"]');

    this.errorMessageTarget.parentNode.parentNode.classList.remove('dn');
    this.errorMessageTarget.innerText = err.message;

    elem.classList.add('error');
  }

  clearErrors(form) {
    [...form.querySelectorAll('.error')].forEach(e => e.classList.remove('error'));

    this.errorMessageTarget.parentNode.parentNode.classList.add('dn');
  }
}
