import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'periodForm', 'goalField', 'setGoalButton' ]

  connect() {
  }

  updateGoal(e){
    e.preventDefault();

    const goalField = this.goalFieldTarget;

    goalField.classList.toggle('dn');
    goalField.querySelector('input').focus();
  }

  validateGoalInput(e) {
    const input = e.target;
    const value = input.value;

    if (value === '') {
      input.classList.remove('error');
      this.setGoalButtonTarget.disabled =  true;
    } else if (/^[\d,\.]+$/.test(value)) {
      input.classList.remove('error');
      this.setGoalButtonTarget.disabled =  false;
    } else {
      input.classList.add('error');
      this.setGoalButtonTarget.disabled =  true;
    }
  }

  redirectToPeriod(e) {
    const beginYear = this.periodFormTarget.querySelector('select[name="begin_year"]').value;
    const beginMonth = this.periodFormTarget.querySelector('select[name="begin_month"]').value;
    const endYear = this.periodFormTarget.querySelector('select[name="end_year"]').value;
    const endMonth = this.periodFormTarget.querySelector('select[name="end_month"]').value;
    const action = this.periodFormTarget.getAttribute('action');
    const uri = `${action}?begin=${beginYear}-${beginMonth}&end=${endYear}-${endMonth}`;

    Turbolinks.visit(uri);
  }


}