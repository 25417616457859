import { Controller } from "stimulus"
import Cleave from 'cleave.js';

export default class extends Controller {
  static targets = [];

  initialize() {
    new Cleave('#card-number', {
      creditCard: true
    });
  }

  validateCardNumber() {
    const cardNumber = this.element.querySelector('#card-number');

    if (Stripe.card.validateCardNumber(cardNumber.value)) {
      cardNumber.classList.remove('error');
    } else {
      cardNumber.classList.add('error');
    }
  }

  validateCVC(e) {
    const cvc = this.element.querySelector('#card-cvc');

    if (Stripe.card.validateCVC(cvc.value, 'US')) {
      cvc.classList.remove('error')
    } else {
      cvc.classList.add('error')
    }
  }

  validateExpiry() {
    const monthSelect = this.element.querySelector('#card-month');
    const yearSelect = this.element.querySelector('#card-year');
    const month = monthSelect.value;
    const year = yearSelect.value;

    if (Stripe.card.validateExpiry(month, year)) {
      monthSelect.parentElement.classList.remove('error');
      yearSelect.parentElement.classList.remove('error');
    } else {
      monthSelect.parentElement.classList.add('error');
      yearSelect.parentElement.classList.add('error');
    }
  }

  validateExpirationMonth() {
    const month = this.element.querySelector('#card-month');
    month.value ? month.parentElement.classList.remove('error') : month.parentElement.classList.add('error');
  }

  validateExpirationYear() {
    const year = this.element.querySelector('#card-year');
    year.value ? year.parentElement.classList.remove('error') : year.parentElement.classList.add('error');
  }
}
