import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'acquiredFromSelect', 'acquiredFromTextField' ];

  acquiredFromChanged(e) {
    const select = e.target;
    const input = this.acquiredFromTextFieldTarget;

    if (select.value === 'Other') {
      select.disabled = true;
      select.parentNode.classList.add('dn');
      input.disabled = false;
      input.classList.remove('dn');
      input.focus();
    }
  }

}
