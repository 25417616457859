import {Controller} from 'stimulus';
import Turbolinks from 'turbolinks';

export default class extends Controller {
  static targets = [
    'link'
  ]

  visit(e) {
    e.stopPropagation();
    e.preventDefault();

    const href = e.currentTarget.querySelector('a[data-target="visitable.link"]');

    if (href) {
      Turbolinks.visit(href.getAttribute('href'));
    }
  }
}