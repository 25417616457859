import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['errorMessage', 'paymentForm'];

  connect() {
    this.hasCardToken = false;
    this.paymentFormTarget.addEventListener("ajax:beforeSend", this.fetchToken);
  }

  disconnect() {
    this.hasCardToken = false;
    this.paymentFormTarget.removeEventListener("ajax:beforeSend", this.fetchToken);
  }

  fetchToken(e) {
    const form = e.target;

    if (!this.hasCardToken && !form.querySelector('.error')) {
      e.preventDefault();

      const submitButton = form.querySelector('[type="submit"]');
      submitButton.disabled = true;
      submitButton.classList.add('loading');

      Stripe.createToken(form, (status, response) => {
        if (response && response.error) {
          submitButton.disabled = false;
          submitButton.classList.remove('loading');

          this.showError(form, response.error)
        } else {
          form.querySelector('input[name*="card_token"]').value = response.id;

          this.hasCardToken = true;
          Rails.fire(form, 'submit');
        }
      });
    }
  }

  pay(e) {
    const form = e.target;
    const submitButton = form.querySelector('[type="submit"]');

    this.clearErrors(form);
    this.validateFields();

    if (form.querySelector('.error') || !this.hasCardToken) {
      e.preventDefault();
    }
  }

  validateFields() {
    const creditCardController = this.application.getControllerForElementAndIdentifier(this.element, 'credit-card');

    this.validateName();
    this.validatePostCode();
    creditCardController.validateCardNumber();
    creditCardController.validateExpiry();
    // creditCardController.validateExpirationYear();
    // creditCardController.validateExpirationMonth();
    creditCardController.validateCVC();
  }

  validatePostCode() {
    const input = this.element.querySelector('#postcode');

    if (input === null) return;

    if (/^\d{5}(?:[-\s]\d{4})?$/.test(input.value)) {
      input.classList.remove('error');
    } else {
      input.classList.add('error');
    }
  }

  validateName() {
    const input = this.element.querySelector('#name-on-card');

    if (input.value !== '') {
      input.classList.remove('error');
    } else {
      input.classList.add('error');
    }
  }

  showError(form, err) {
    const name = err.param.replace('_', '-');
    const elem = form.querySelector('[data-stripe="' + name + '"]');

    this.errorMessageTarget.parentNode.parentNode.classList.remove('dn');
    this.errorMessageTarget.innerText = err.message;

    elem.classList.add('error')
  }

  clearErrors(form) {
    [...form.querySelectorAll('.error')].forEach(e => e.classList.remove('error'));

    this.errorMessageTarget.parentNode.parentNode.classList.add('dn')
  }
}
