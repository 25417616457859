import { Controller } from 'stimulus';
import { DirectUpload } from '@rails/activestorage';
import sum from 'lodash/sum';

export default class extends Controller {
  static targets = [
    'imageField',
    'noDataState',
    'error',
    'uploadProgress'
  ];

  connect() {
    this.imageTemplate = this.imageFieldTarget.cloneNode(true);
  }

  imageSelected(e) {
    this.progress = {};
    const uploadField = e.currentTarget;
    const uploadUrl = uploadField.dataset.directUploadUrl;
    const submitButton = this.element.querySelector('[type="submit"]');

    this.errorTarget.classList.add('dn');
    submitButton.disabled = true;

    [...uploadField.files].forEach(file => {
      const directUpload = new DirectUpload(file, uploadUrl, this);
      const imageField = this.imageTemplate.cloneNode(true);

      directUpload.create((error, blob) => {
        if (error) {
          this._logError(error);

          this.errorTarget.classList.remove('dn');
          this.uploadProgressTarget.classList.add('dn');
          submitButton.disabled = false;
          uploadField.value = null;
        } else {
          const imageUrl = `${uploadUrl.replace(/direct_uploads/, 'blobs/')}${blob.signed_id}/${encodeURIComponent(blob.filename)}`;
          const hiddenInput = imageField.querySelector('input[type="hidden"]');
          const lastUpload = this.imageFieldTargets[this.imageFieldTargets.length - 1];

          hiddenInput.value = blob.signed_id;
          hiddenInput.disabled = false;

          imageField.firstElementChild.style['background-image'] = `url(${imageUrl})`;
          imageField.classList.remove('dn');
          this.imageFieldTarget.parentNode.insertBefore(imageField, lastUpload.nextSibling);

          this.noDataStateTargets.forEach(el => {
            el.classList.add('dn');
            el.classList.remove('add-photo');
          });

          if (this._isComplete()) {
            this.uploadProgressTarget.classList.add('dn');
            submitButton.disabled = false;
            uploadField.value = null;
          }
        }
      });
    });
  }

  removeUpload(e) {
    const upload = e.target.parentNode;
    const hiddenInputDestroy = upload.querySelector('input[name*="_destroy"]');

    if (hiddenInputDestroy) {
      hiddenInputDestroy.disabled = false;
      hiddenInputDestroy.value = 1;
      upload.querySelector('input[name*="id"]').disabled = false;
      upload.style.display = 'none';
    } else {
      upload.parentNode.removeChild(upload);
    }

    if (this.imageFieldTargets.length === 1) {
      this.noDataStateTargets.forEach(e => {
        e.classList.remove('dn');
        e.classList.add('add-photo');
      });
    }
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", event => this._updateProgress(event))
  }

  _isComplete() {
    return _.every(this.progress, (v, k) => v === parseInt(k));
  }

  _updateProgress(e) {
    this.progress[e.total] = e.loaded;
    const total = sum(_.map(this.progress, (v, k) => parseInt(k)));
    const loaded = sum(_.values(this.progress));

    const percentComplete = Math.floor((loaded / total) * 100);
    this.uploadProgressTarget.classList.remove('dn');
    this.uploadProgressTarget.style.width = percentComplete.toString() + '%'
  }

  _logError(error) {
    if (window.Rollbar) {
      Rollbar.error(error);
    } else {
      console.error(error);
    }
  }
}
