import { Controller } from 'stimulus';
import _ from 'lodash/core';

export default class extends Controller {
  static targets = [ 'street1', 'street2', 'state', 'city', 'postcode' ]

  connect() {
  }

  autocomplete(e) {
    const input = e.currentTarget;
    const autocomplete = new google.maps.places.Autocomplete(input, {
     types: ['address'],
     componentRestrictions: { country: 'us' }
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        return;
      } else {
        const streetNumber = (_.find(place.address_components, c => c.types[0] === 'street_number') || {}).short_name;
        const street = (_.find(place.address_components, c => c.types[0] === 'route') || {}).short_name;

        this.street1Target.value = [streetNumber, street].join(' ');
        this.stateTarget.value = (_.find(place.address_components, c => c.types[0] === 'administrative_area_level_1') || {}).short_name;
        this.cityTarget.value =  (_.find(place.address_components, c => c.types[0] === 'locality') || {}).long_name;
        this.postcodeTarget.value = (_.find(place.address_components, c => c.types[0] === 'postal_code') || {}).long_name;
      }
    });
  }
}