import { Controller } from 'stimulus';
import { DirectUpload } from '@rails/activestorage';

export default class extends Controller {
  static targets = ['upload'];


  imageSelected(e) {
    e.preventDefault();

    const uploadField = e.currentTarget;
    const uploadUrl = uploadField.dataset.directUploadUrl;
    const uploadTemplate = this.uploadTarget.cloneNode(true);

    let file;
    let directUpload;

    if (uploadField.files) {
      file = uploadField.files[0];
      directUpload = new DirectUpload(file, uploadUrl, );
    } else {
      return;
    }

    if (!file) return;

    // Reject files larger than 5 MB
    if (file.size > 5*1024*1024) {
      return alert('Image is too large, please choose a smaller image');
    }

    directUpload.create((error, blob) => {
      const imageUrl = `${uploadUrl.replace(/direct_uploads/, 'blobs/')}${blob.signed_id}/${encodeURIComponent(blob.filename)}`;
      const hiddenInput = uploadTemplate.querySelector('input[type="hidden"]');
      const lastUpload = this.uploadTargets[this.uploadTargets.length - 1];

      hiddenInput.value = blob.signed_id;
      hiddenInput.disabled = false;

      uploadTemplate.style['background-image'] = `url(${imageUrl})`;
      uploadTemplate.classList.remove('dn');
      uploadTemplate.classList.add('dib');

      this.uploadTarget.parentNode.insertBefore(uploadTemplate, lastUpload.nextSibling);
    });

    uploadField.value = null;
  }

  removeUpload(e) {
    const upload = e.target.parentNode;
    const hiddenInputDestroy = upload.querySelector('input[name*="_destroy"]');


    if (hiddenInputDestroy) {
      hiddenInputDestroy.disabled = false;
      hiddenInputDestroy.value = 1;
      upload.querySelector('input[name*="id"]').disabled = false;
      upload.classList.remove('dib');
      upload.classList.add('dn');
    } else {
      upload.parentNode.removeChild(upload);
    }
  }
}
