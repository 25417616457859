import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = [
    'main',
    'pop',
    'mainButton',
    'popButton',
    'popCloseButton'
  ]

  initialize() {
    this._bindMainSwipe();
    this._bindPopSwipe();

    this.desktopPopMenu = [...document.querySelectorAll('nav.pop-menu')];
    document.querySelector('body').addEventListener('click', e => {
      this.desktopPopMenu.forEach(nav => nav.classList.add('hidden'));
    });
  }

  toggleMain(e) {
    e.stopPropagation();
    e.preventDefault();

    this.closePop();
    this.mainButtonTarget.classList.toggle('hidden');
    this.mainTarget.classList.toggle('hidden');
  }

  togglePop(e) {
    e.stopPropagation();
    e.preventDefault();

    this.closeMain();
    this.popTarget.classList.toggle('hidden');
    if (this.hasPopButtonTarget) this.popButtonTarget.classList.toggle('hidden');
    if (this.hasPopCloseButtonTarget) this.popCloseButtonTarget.classList.toggle('hidden');
  }

  closeAll(e) {
    e && e.preventDefault();

    this.closeMain();
    this.closePop();
  }

  closePop(e) {
    if (!this.hasPopTarget) return;
    e && e.preventDefault();

    this.popTarget.classList.add('hidden');
    if (this.hasPopButtonTarget) this.popButtonTarget.classList.remove('hidden');
    if (this.hasPopCloseButtonTarget) this.popCloseButtonTarget.classList.add('hidden');
  }

  closeMain(e) {
    if (!this.hasMainTarget) return;
    e && e.preventDefault();

    this.mainTarget.classList.add('hidden');
    this.mainButtonTarget.classList.remove('hidden');
  }

  _bindMainSwipe() {
    if (!this.hasMainTarget) return;

    let pageWidth = window.innerWidth || document.body.clientWidth;
    let treshold = Math.max(1,Math.floor(0.01 * (pageWidth)));
    let touchstartX = 0;
    let touchstartY = 0;
    let touchendX = 0;
    let touchendY = 0;

    const limit = Math.tan(45 * 1.5 / 180 * Math.PI);

    this.mainTarget.addEventListener('touchstart', function(event) {
        touchstartX = event.changedTouches[0].screenX;
        touchstartY = event.changedTouches[0].screenY;
    }, false);

    this.mainTarget.addEventListener('touchend', event => {
        touchendX = event.changedTouches[0].screenX;
        touchendY = event.changedTouches[0].screenY;

        let x = touchendX - touchstartX;
        let y = touchendY - touchstartY;
        let xy = Math.abs(x / y);
        let yx = Math.abs(y / x);

        if (Math.abs(x) > treshold || Math.abs(y) > treshold) {
          if (yx <= limit && x < 0) {
            this.closeMain();
          }
        }

    }, false);
  }

  _bindPopSwipe() {
    if (!this.hasPopTarget) return;

    let pageWidth = window.innerWidth || document.body.clientWidth;
    let treshold = Math.max(1,Math.floor(0.01 * (pageWidth)));
    let touchstartX = 0;
    let touchstartY = 0;
    let touchendX = 0;
    let touchendY = 0;

    const limit = Math.tan(45 * 1.5 / 180 * Math.PI);

    this.popTarget.addEventListener('touchstart', function(event) {
        touchstartX = event.changedTouches[0].screenX;
        touchstartY = event.changedTouches[0].screenY;
    }, false);

    this.popTarget.addEventListener('touchend', event => {
        touchendX = event.changedTouches[0].screenX;
        touchendY = event.changedTouches[0].screenY;

        let x = touchendX - touchstartX;
        let y = touchendY - touchstartY;
        let xy = Math.abs(x / y);
        let yx = Math.abs(y / x);

        if (Math.abs(x) > treshold || Math.abs(y) > treshold) {
          if (yx <= limit && x > 0) {
            this.closePop();
          }
        }

    }, false);
  }

  teardown() {
    this.closeAll();
  }
}