import { Controller } from 'stimulus';
import debounce from 'lodash/debounce';
import axios from 'axios';

export default class extends Controller {
  static targets = [
    'items',
    'loadMore',
  ]

  connect() {
    this.currentPage = 1;
    this.scroll = debounce(this.scroll, 100);
    this.loading = false;
    this.totalPages = parseInt(this.data.get('pages'));
  }

  scroll(e) {
    if (this.hasLoadMoreTarget) return;

    const threshold = 300;

    if (window.pageYOffset > document.documentElement.scrollHeight - window.innerHeight - 300) {
      this._loadNextPage();
    }
  }

  loadMore(e) {
    e.preventDefault();
    this._loadNextPage();
  }

  _loadNextPage() {
    if (!this.loading && this.currentPage < this.totalPages) {
      this.currentPage++;
      this.loading = true;

      axios.get(`${this.data.get('url')}${window.location.search || '?'}&page=${this.currentPage}`)
        .then(result => {
          this.itemsTarget.firstElementChild.insertAdjacentHTML('beforeend', result.data);
          this.loading = false;
        });

      if (this.hasLoadMoreTarget && this.currentPage >= this.totalPages) {
        this.loadMoreTarget.classList.add('dn');
      }
    }
  }

}