import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'satisfied',
    'dissatisfied',
    'comments',
    'customMessage',
    'choices',
    'description'
  ];

  selectChoice(e) {
    const radioButton = e.currentTarget.querySelector('input[type="radio"');
    const form = this.element.querySelector('form');

    radioButton.checked = true;
    form.submit();
  }

  toggleFeedback(e) {
    const checkBox = e.currentTarget;
    const textArea = this.customMessageTarget.querySelector('textarea');

    if (checkBox.checked) {
      this.customMessageTarget.classList.remove('dn');
      textArea.disabled = false;
    } else {
      this.customMessageTarget.classList.add('dn');
      textArea.value = '';
      textArea.disabled = true;
    }
  }

  showSatisfied() {
    this.choicesTarget.querySelector('input[id="feedback_satisfied_true"]').checked = true;
    this.satisfiedTarget.querySelector('#feedback_body').disabled = false;

    this.commentsTarget.classList.remove('dn');
    this.satisfiedTarget.classList.remove('dn');
    this.dissatisfiedTarget.classList.add('dn');
    this.choicesTarget.classList.add('dn');
    this.descriptionTarget.classList.add('dn');
  }

  showDissatisfied() {
    this.choicesTarget.querySelector('input[id="feedback_satisfied_false"]').checked = true;
    this.dissatisfiedTarget.querySelector('#feedback_body').disabled = false;
    this.commentsTarget.classList.remove('dn');
    this.dissatisfiedTarget.classList.remove('dn');
    this.satisfiedTarget.classList.add('dn');
    this.choicesTarget.classList.add('dn');
    this.descriptionTarget.classList.add('dn');
  }

  goBack(e) {
    e.preventDefault();

    this.choicesTarget.classList.remove('dn');
    this.descriptionTarget.classList.remove('dn');
    this.commentsTarget.classList.add('dn');
  }
}