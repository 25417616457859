import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'placesInput',
    'googleUrl',
    'googlePlace',
  ];

  initialize() {
    this.initPlacesAutocomplete();

    const placeId = this.data.get('googlePlaceId');

    if (placeId.length > 0) {
      const service = new google.maps.places.PlacesService(this.googlePlaceTarget);

      service.getDetails(
        {
          placeId: placeId,
          fields: ['name', 'formatted_address']
        },
        (place, status) => {
          if (status === 'OK') {
            const businessInfo = `<span class="b">${place.name}</span><br\><br\>${place.formatted_address.slice(0, -5)}`;
            this.googlePlaceTarget.innerHTML = businessInfo;
          } else {
            this.googlePlaceTarget.innerText = 'Error finding business'
          }
        }
      )
    }
  }

  toggleGoogleUrlField(e) {
    const radioButton = e.currentTarget.value;
    const placeIdInput = this.googleUrlTarget.querySelector('input[type="hidden"]');
    const placesSearch = this.googleUrlTarget.querySelector('input[type="text"]');

    if (radioButton === 'true') {
      this.googleUrlTarget.classList.remove('dn');

      placeIdInput.disabled = false;
      placesSearch.focus();
    } else if (radioButton === 'false') {
      this.googleUrlTarget.classList.add('dn');

      placesSearch.value = '';
      placeIdInput.value = '';
      placeIdInput.disabled = true;
    }
  }

  removeGooglePlaceId(e) {
    e.preventDefault();
    const placeIdInput = e.currentTarget.parentNode.querySelector('input[type="hidden"]');
    const form = this.element.querySelector('form');
    placeIdInput.value = '';
    form.submit();
  }

  initPlacesAutocomplete(e) {
    const input = this.placesInputTarget;
    const placeIdInput = this.googleUrlTarget.querySelector('input[type="hidden"]');

    const autocomplete = new google.maps.places.Autocomplete(input, {
      types: ['establishment'],
      componentRestrictions: { country: 'us' }
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      placeIdInput.value = place.place_id;
    });
  }
}
