import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['description', 'quantity', 'price'];

  initialize() {
  }

  connect() {
  }

  remove(e) {
    const form = document.querySelector('.accountable-form form');
    const accountableController = this.application.getControllerForElementAndIdentifier(form, 'accountable');
    const row = e.target.closest('tr');
    const idInput = row.querySelector('input[name*="[id]"]');
    const destroyInput = row.querySelector('input[name*="_destroy"]');

    if (idInput) {
      idInput.removeAttribute('disabled');
      destroyInput.removeAttribute('disabled');
      destroyInput.value = '1';
      row.style.display = 'none';
    } else {
      e.target.closest('tbody').removeChild(row);
    }

    accountableController.updateTotals();
  }

  normalizePrice() {
    const price = this.priceTarget;

    if (!/\./.test(price.value) && price.value !== '') price.value = price.value + '.00';
  }

  validate() {
    this.validateDescription();
    this.validateQuantity();
    this.validatePrice();
  }

  validateDescription() {
    const description = this.descriptionTarget;

    if (description.value.trim().length > 1) {
      description.classList.remove('error');
    } else {
      description.classList.add('error');
    }
  }

  validateQuantity() {
    const quantity = this.quantityTarget;

    if (/^\d+$/.test(quantity.value.trim())) {
      quantity.classList.remove('error');
    } else {
      quantity.classList.add('error');
    }
  }

  validatePrice() {
    const price = this.priceTarget;

    if (/^\d+(\.\d{1,2})?$/.test(price.value.trim())) {
      price.classList.remove('error');
    } else {
      price.classList.add('error');
    }
  }
}
