import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'timeSelect', 'jobList', 'job' ]

  initialize() {
  }

  toggleJobList(e) {
    if (e.currentTarget.value === 'true' && e.currentTarget.checked === true) {
      // Existing job is selected
      this.jobListTarget.classList.remove('dn');
    } else {
      this.jobTargets.forEach(j => j.querySelector('input').disabled = true);
      this.jobListTarget.classList.add('dn');
    }
  }

  selectJob(e) {
    const selectedJob = e.currentTarget;
    const selectedClasses = ['b--light-blue', 'bg-washed-blue'];

    this.jobTargets.forEach(job => {
      job.classList.remove(...selectedClasses);
      job.querySelector('input').disabled = true;
    });

    selectedJob.classList.add(...selectedClasses);
    selectedJob.querySelector('input').disabled = false;
  }

}