import 'polyfills';
import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage'
import Turbolinks from 'turbolinks';

Rails.start();
ActiveStorage.start();
Turbolinks.start();

// Disable return form submissions on input fields
window.addEventListener('keydown', e => {
  if (e.keyIdentifier === 'U+000A' || e.keyIdentifier === 'Enter' || e.keyCode === 13) {
    if (e.target.nodeName === 'INPUT' && e.target.type === 'text') {
      e.preventDefault();
      return false;
    }
  }
}, true);

// Handle ajax form submission errors
document.addEventListener('ajax:complete', e => {
  const xhr = e.detail[0];

  if ((xhr.getResponseHeader('Content-Type') || '').substring(0, 9) === 'text/html') {
    const referrer = window.location.href;
    const snapshot = Turbolinks.Snapshot.wrap(xhr.response);

    Turbolinks.controller.cache.put(referrer, snapshot);
    Turbolinks.visit(referrer, { action: 'restore' });
  }
}, false);

document.addEventListener('DOMContentLoaded', () => {
  if (!window.Stripe) return;

  const stripeKey = document.querySelector('meta[name="stripe-key"]').getAttribute('content');

  window.Stripe.setPublishableKey(stripeKey);
});

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';



import AccountableController from '../controllers/accountable_controller';
import AddressController from '../controllers/address_controller';
import BusinessController from '../controllers/business_controller';
import CalendarController from '../controllers/calendar_controller';
import CompanyController from '../controllers/company_controller';
import CustomerController from '../controllers/customer_controller';
import DateSelectController from '../controllers/date_select_controller';
import DropdownController from '../controllers/dropdown_controller';
import AppointmentController from '../controllers/appointment_controller';
import FeedbackController from '../controllers/feedback_controller';
import PaginationController from '../controllers/pagination_controller';
import InvoiceController from '../controllers/invoice_controller';
import JobController from '../controllers/job_controller';
import LineItemController from '../controllers/line_item_controller';
import MapController from '../controllers/map_controller';
import NavController from '../controllers/nav_controller';
import NoteController from '../controllers/note_controller';
import VisitableController from '../controllers/visitable_controller';
import CreditCardController from '../controllers/credit_card_controller';
import PaymentsController from '../controllers/payments_controller';
import ImagesController from '../controllers/images_controller';
import ReviewsController from '../controllers/reviews_controller';
import ExpenseController from '../controllers/expense_controller';

const application = Application.start();

// Add support for teardown of controllers
document.addEventListener('turbolinks:before-cache', function() {
  application.controllers.forEach(function(controller){
    if(typeof controller.teardown === 'function') {
      controller.teardown();
    }
  });
});

application.register('accountable', AccountableController);
application.register('address', AddressController);
application.register('business', BusinessController);
application.register('calendar', CalendarController);
application.register('company', CompanyController);
application.register('customer', CustomerController);
application.register('date-select', DateSelectController);
application.register('dropdown', DropdownController);
application.register('appointment', AppointmentController);
application.register('feedback', FeedbackController);
application.register('pagination', PaginationController);
application.register('invoice', InvoiceController);
application.register('job', JobController);
application.register('line-item', LineItemController);
application.register('map', MapController);
application.register('nav', NavController);
application.register('note', NoteController);
application.register('visitable', VisitableController);
application.register('credit-card', CreditCardController);
application.register('payments', PaymentsController);
application.register('images', ImagesController);
application.register('reviews', ReviewsController);
application.register('expense', ExpenseController);
