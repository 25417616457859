import { Controller } from 'stimulus';
import debounce from 'lodash/debounce';

export default class extends Controller {
  static targets = [];

  initialize() {}

  connect() {}

  toggleBillingAddress(e) {
    const checkBox = e.currentTarget;
    const address = this.element.querySelector('#address');
    const billingAddress = this.element.querySelector('#billing-address');
    const addressFields = [...address.querySelectorAll('input, select')];
    const billingAddressFields = [...billingAddress.querySelectorAll('input, select')];

    if (checkBox.checked) {
      billingAddress.classList.remove('dn');
      billingAddressFields.forEach(f => f.disabled = false);
      addressFields.forEach(f => f.name = f.name.replace('[customer_attributes]', ''));
    } else {
      billingAddress.classList.add('dn');
      billingAddressFields.forEach(f => f.disabled = true);
      [...billingAddress.querySelectorAll('input')].forEach(i => i.value = '');
      billingAddress.querySelector('select').selectedIndex = 0;
      addressFields.forEach(f => f.name = f.name.replace('job', 'job[customer_attributes]'));
    }
  }

  toggleServiceAddress(e) {
    const checkBox = e.currentTarget;
    const serviceAddress = this.element.querySelector('#service-address [data-controller="address"]');
    const serviceAddressFields = [...serviceAddress.querySelectorAll('input, select')];
    const idInput = serviceAddress.parentElement.querySelector('input[name*="[id]"]');
    const destroyInput = serviceAddress.parentElement.querySelector('input[name*="_destroy"]');

    if (checkBox.checked) {
      serviceAddress.parentElement.classList.remove('dn');
      serviceAddressFields.forEach(f => f.disabled = false);
      if (idInput) destroyInput.disabled = true;
    } else {
      serviceAddress.parentElement.classList.add('dn');
      [...serviceAddress.querySelectorAll('input')].forEach(i => i.value = '');
      serviceAddress.querySelector('select').selectedIndex = 0;
      serviceAddressFields.forEach(f => f.disabled = true);
      if (idInput) {
        idInput.disabled = false;
        destroyInput.disabled = false;
        destroyInput.value = true;
      }
    }
  }
}
